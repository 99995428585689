<template>
  <SelectContainer class="mt-4">
    <Select
      :value="filter.numbers"
      :options="filterData.numbers"
      :placeholder="$t('please-insert-a-value-or-click-in-the-arrow')"
      input-id="numbers"
      multiple
      full-length-display-value
      @change="onChange"
    />
  </SelectContainer>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import SelectContainer from '@autobid/ui/components/elements/select/SelectContainer.vue'
import Select from '@autobid/ui/components/elements/select/Select.vue'

const auctionStoreKey = inject('auctionStoreKey')
const { filter } = storeToRefs(useAuctionStore(auctionStoreKey))

const filterData = inject('filterData') as Ref<ParsedFilterData>

const onChange = (v) => (filter.value.numbers = v ?? [])
</script>
